import { Modal } from 'antd';
import React from 'react';
import TableComponent from '../../../components/CommonTable';

export default function SessionUserList({
  isModalOpen,
  setIsModalOpen,
  participantsData,
  participantsLoading,
}) {
  const ParticipantsColums = [
    {
      title: 'Name',
      dataIndex: 'userName',
      key: ' userName',
      render: (_, value) => value?.userName || '-',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      render: (_, value) =>
        Number(value.score) > 0
          ? Math?.round(Number(value?.score) * 100) / 100
          : 0,
    },
    {
      title: 'Total Correct Answers',
      dataIndex: 'totalCorrectAnswers',
      key: 'totalCorrectAnswers',
      render: (_, value) => value?.totalCorrectAnswers,
    },
  ];

  return (
    <Modal
      title="User List"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={false}
    >
      <TableComponent
        columns={ParticipantsColums}
        data={participantsData?.getParticipants?.participants}
        rowClassName="pointer"
        loadingData={participantsLoading}
      />
    </Modal>
  );
}

import React from 'react';
import QuizForm from './components/QuizForm';

const EditQuiz = () => (
  <div>
    <QuizForm edit />
  </div>
);

export default EditQuiz;

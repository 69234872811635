import { UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin, Tabs, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useSearchParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import Logo from '../../assets/images/logo.png';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import useTimer from '../../hooks/useTimer';
import './auth.less';
import { SIGNUP, VERIFY_LOGIN_OTP } from './graphql/Mutations';

const { Paragraph } = Typography;
const { email, otp } = formValidatorRules;

const Login = () => {
  const [EmailForm] = Form.useForm();
  const [otpForm] = Form.useForm();
  const { navigate } = useRouter();
  const { initializeAuth } = useContext(AppContext);
  const [loginLoading, setLoginLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { time, start, end, setTime } = useTimer(180);
  const userEmail = useWatch('email', EmailForm) || '';
  const userOtp = useWatch('otp', otpForm) || '';

  const [loginMutate] = useMutation(SIGNUP, {
    onError() {},
  });

  const [VerifyLoginOtp] = useMutation(VERIFY_LOGIN_OTP, {
    onError() {},
  });

  function successCallback(accessToken, userData, refreshToken) {
    initializeAuth(accessToken, userData, refreshToken);
    navigate('/', { replace: true });
  }
  const handleFormEmail = async (values) => {
    try {
      setSearchParams({ email: values?.email });
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
      };
      const { data } = await loginMutate({
        variables: {
          data: {
            ...formValues,
          },
        },
      });
      if (data) {
        end();
        start();
        setSearchParams({
          email: values?.email,
          key: '2',
          id: data?.login?.user?.id,
        });
      }
    } catch (error) {
      //
    }
  };

  const handleFormOtp = async (value) => {
    try {
      setLoginLoading(true);
      const { data } = await VerifyLoginOtp({
        variables: {
          data: {
            otp: value?.otp,
            uid: searchParams?.get('id'),
          },
        },
      });

      if (data?.verifyLoginOtp?.user?.isVerified) {
        const accessToken = data?.verifyLoginOtp?.accessToken;
        const userData = { ...data?.verifyLoginOtp?.user };
        const refreshToken = userData?.refreshToken;
        if (successCallback) {
          successCallback(accessToken, userData, refreshToken);
          setLoginLoading(false);
        }
      }
    } catch (error) {
      //
    } finally {
      setLoginLoading(false);
      end();
      setTime(0);
      otpForm.setFieldValue('otp', '');
    }
  };

  useEffect(() => {
    start();
    EmailForm.setFieldValue('email', searchParams?.get('email'));
  }, [searchParams?.get('key')]);

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex-col">
        <Card className="full-width">
          <div className="login-main">
            <Spin spinning={loginLoading} wrapperClassName="full-width">
              <div className="text-center mb-30 auth-logo">
                <img className="app-logo" src={Logo} alt="qrizo-logo" />
              </div>
              <Tabs
                defaultActiveKey="1"
                activeKey={searchParams?.get('key') || '1'}
                className="auth-tabs-component"
                items={[
                  {
                    key: '1',
                    children: (
                      <Form
                        className="login-email-form"
                        name="login"
                        initialValues={{ remember: true }}
                        onFinish={handleFormEmail}
                        size="large"
                        form={EmailForm}
                        layout="vertical"
                      >
                        <Form.Item
                          name="email"
                          label="Enter Email"
                          rules={[
                            { required: true, message: 'Please enter email!' },
                            email,
                          ]}
                        >
                          <Input
                            prefix={<UserOutlined />}
                            placeholder="Enter email"
                          />
                        </Form.Item>
                        <Form.Item className="full-width mb-8">
                          <Button
                            type="primary"
                            className="full-width"
                            htmlType="submit"
                            disabled={isEmpty(userEmail)}
                          >
                            Send OTP
                          </Button>
                        </Form.Item>
                      </Form>
                    ),
                  },
                  {
                    key: '2',
                    children: (
                      <div className="otp-verification-form">
                        <p>OTP Verification</p>
                        <p>
                          Enter the 6 digit verification code received on your{' '}
                          Email ID
                        </p>
                        <p>
                          {searchParams?.get('email')}{' '}
                          <span
                            onClick={() => {
                              setSearchParams({
                                email: searchParams?.get('email'),
                                key: '1',
                              });
                            }}
                          >
                            change
                          </span>
                        </p>
                        <p>Verification Code</p>
                        <Form
                          name="login"
                          initialValues={{ remember: true }}
                          onFinish={handleFormOtp}
                          size="large"
                          form={otpForm}
                          className="login-otp-form"
                        >
                          <Form.Item
                            name="otp"
                            className="mb-24"
                            rules={[
                              { required: true, message: 'Please enter otp!' },
                            ]}
                          >
                            <OtpInput
                              value={otp}
                              numInputs={6}
                              renderInput={(props) => <input {...props} />}
                            />
                          </Form.Item>
                          <Paragraph className="send-again">
                            Time Left{' '}
                            {`${Math.floor(time / 60)
                              ?.toString()
                              ?.padStart(2, 0)}
                            :${(time % 60)?.toString()?.padStart(2, 0)}`}
                          </Paragraph>
                          {time !== 0 ? (
                            <Form.Item className="mb-8">
                              <Button
                                type="primary"
                                className="full-width"
                                htmlType="submit"
                                disabled={userOtp?.length < 6 || loginLoading}
                              >
                                Verify
                              </Button>
                            </Form.Item>
                          ) : (
                            <Button
                              type="primary"
                              className="full-width"
                              onClick={async () => {
                                end();
                                start();
                                const { data } = await loginMutate({
                                  variables: {
                                    data: {
                                      email: searchParams?.get('email'),
                                    },
                                  },
                                });
                                if (data) {
                                  setSearchParams({
                                    email: searchParams?.get('email'),
                                    key: '2',
                                    id: data?.login?.user?.id,
                                  });
                                }
                              }}
                            >
                              Resend
                            </Button>
                          )}
                        </Form>
                      </div>
                    ),
                  },
                ]}
              />
            </Spin>
          </div>
        </Card>
        <div className="text-center login-copyrights">
          Logicwind © {new Date()?.getFullYear()}
        </div>
      </div>
    </div>
  );
};

export default Login;

import { gql } from '@apollo/client';

export const CREATE_CONFIG = gql`
  mutation CreateConfig($data: CreateConfigInputData!) {
    createConfig(data: $data) {
      message
    }
  }
`;

export const UPDATE_CONFIG = gql`
  mutation UpdateConfig($data: UpdateConfigInputData!) {
    updateConfig(data: $data) {
      message
    }
  }
`;

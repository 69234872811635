import { gql } from '@apollo/client';

export const SIGNUP = gql`
  mutation Login($data: LoginInputData!) {
    login(data: $data) {
      message
      user {
        refreshToken
        isVerified
        id
        email
      }
    }
  }
`;

export const VERIFY_LOGIN_OTP = gql`
  mutation VerifyLoginOtp($data: LoginOtpVerificationInput) {
    verifyLoginOtp(data: $data) {
      accessToken
      message
      user {
        email
        id
        isVerified
        refreshToken
        isConfigSet
      }
    }
  }
`;

import React from 'react';
import QuizForm from './components/QuizForm';

const AddQuiz = () => (
  <div>
    <QuizForm />
  </div>
);

export default AddQuiz;

import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { formats, modules } from './QuillToolbar';

const QuillEditor = ({ value, ...rest }) => (
  <div className="text-editor">
    <EditorToolbar />
    <ReactQuill
      theme="snow"
      value={value}
      placeholder="Write something awesome..."
      modules={modules}
      formats={formats}
      className="editor"
      {...rest}
    />
  </div>
);

export default QuillEditor;

import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';
import LoaderComponent from './components/LoaderComponent';

function PublicRoute() {
  const { authLoading, getToken } = useContext(AppContext);
  const idToken = getToken();
  if (authLoading) {
    return <LoaderComponent />;
  }
  return idToken ? <Navigate to={ROUTES?.MAIN} /> : <Outlet />;
}

export default PublicRoute;

import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  FloatButton,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { map } from 'lodash';
import React, { useState } from 'react';
import { ORDER, ROUTES, SORT_ON, STATUS_LIST } from '../../common/constants';
import EmptyStatePage from '../../components/EmptyStatePage';
import LoaderComponent from '../../components/LoaderComponent';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import { CREATE_SESSION, DELETE_QUIZ } from './graphql/Mutations';
import { GET_QUIZ } from './graphql/Queries';

const { Meta } = Card;
const { Text } = Typography;

function NewQuizz() {
  const { navigate } = useRouter();
  const [isFilter, setIsFIlter] = useState({
    search: '',
    order: ORDER[0]?.value,
    sortOn: SORT_ON[0]?.value,
  });

  const { data: quizList, loading, refetch } = useQuery(GET_QUIZ, {
    variables: {
      filter: {
        search: isFilter?.search,
        sortBy: isFilter?.order,
        sortOn: isFilter?.sortOn,
      },
    },
    fetchPolicy: 'network-only',
    onError() {},
  });
  const [createSession] = useMutation(CREATE_SESSION, { onError() {} });

  const [deleteQuiz] = useMutation(DELETE_QUIZ, {
    onCompleted() {
      refetch({
        filter: {
          search: isFilter?.search,
          sortBy: isFilter?.order,
          sortOn: isFilter?.sortOn,
        },
      });
    },
    onError() {},
  });
  const handleHostLink = async (quiz, action = '') => {
    const { data: session, errors } = await createSession({
      variables: {
        data: {
          quizId: quiz?.id,
        },
      },
    });
    if (!errors && session?.createSession?.sessions?.slug) {
      if (action === 'copy') {
        return Promise.resolve(
          `${process.env.REACT_APP_TV_APP_URL}${session?.createSession?.sessions?.slug}`,
        );
      }
      // eslint-disable-next-line no-undef
      window.open(
        `${process.env.REACT_APP_TV_APP_URL}${session?.createSession?.sessions?.slug}`,
        '_blank',
      );
    }
  };

  return (
    <>
      <Card
        className="ant-body-scroll"
        title={
          <>
            <div className="d-flex justify-between">
              <div>
                <Space size="small" wrap>
                  <Text type="secondary">Sort By</Text>
                  <SearchComponent
                    getData={(text) => {
                      setIsFIlter((prev) => ({
                        ...prev,
                        search: text,
                      }));
                    }}
                  />
                  <Select
                    className="order-by-select-input"
                    placeholder="Select list order"
                    defaultValue={ORDER[0]?.value}
                    onChange={(value) =>
                      setIsFIlter((prev) => ({
                        ...prev,
                        order: value,
                      }))
                    }
                    options={ORDER}
                  />
                </Space>
              </div>
              <Button type="primary" onClick={() => navigate(ROUTES.ADD_QUIZ)}>
                Add Quiz
              </Button>
            </div>
          </>
        }
      >
        {loading && <LoaderComponent />}

        <FloatButton.BackTop
          // eslint-disable-next-line no-undef
          target={() => document.querySelector('.ant-card-body')}
          visibilityHeight={100}
        />
        <div className="card-body-wrapper">
          {!loading && quizList?.getQuiz?.quiz?.length === 0 && (
            <EmptyStatePage />
          )}
          <Row gutter={[16, 16]}>
            {map(quizList?.getQuiz?.quiz, (data) => (
              <Col
                key={data?.id}
                xs={24}
                sm={12}
                md={8}
                lg={6}
                xl={6}
                className="actions"
              >
                <Card
                  className="quiz-card full-width"
                  actions={[
                    <Typography.Paragraph
                      onClick={() => handleHostLink(data)}
                      key="copy-link"
                      className="copy-text"
                      copyable={{
                        icon: [
                          <span key="copying" className="copy-link">
                            <CopyOutlined key="copy-icon" color="#ffffff" />
                          </span>,
                          <span key="copied">
                            <CheckOutlined
                              key="copied-icon"
                              className="copy-icon"
                            />
                          </span>,
                        ],
                        tooltips: 'Copy quiz link',
                        text: () => handleHostLink(data, 'copy'),
                      }}
                    >
                      <Tooltip
                        title="Redirecting to quiz screen"
                        className="link"
                      >
                        <p className="link-text">Link</p>
                      </Tooltip>
                    </Typography.Paragraph>,
                    <Tooltip key="detail" title="View" className="link">
                      <Button
                        type="text"
                        onClick={() => {
                          navigate(`/details/${data?.id}`);
                        }}
                      >
                        <EyeOutlined />
                      </Button>
                    </Tooltip>,
                    <Tooltip key="edit" title="Edit" className="link">
                      <Button
                        type="text"
                        onClick={() => {
                          navigate(`/edit/${data?.id}`);
                        }}
                      >
                        <EditOutlined />
                      </Button>
                    </Tooltip>,
                    <Tooltip key="delete" title="Delete" className="link">
                      <Button
                        type="text"
                        onClick={() =>
                          deleteQuiz({
                            variables: { data: { quizId: data?.id } },
                          })
                        }
                      >
                        <DeleteOutlined />
                      </Button>
                    </Tooltip>,
                  ]}
                >
                  <Meta
                    title={
                      <div className="card-title d-flex justify-between">
                        <p className="m-0 quiz-title">{data?.title}</p>
                        <Tag
                          color={
                            data?.publishStatus === STATUS_LIST.PUBLISHED.value
                              ? 'success'
                              : 'error'
                          }
                        >
                          {STATUS_LIST?.[data?.publishStatus]?.name}
                        </Tag>
                      </div>
                    }
                    description={
                      <Typography.Paragraph ellipsis={{ rows: 2 }}>
                        {data?.description}
                      </Typography.Paragraph>
                    }
                  />
                  <div className="d-flex justify-around text-center">
                    <div>
                      <p className="mb-0">Questions</p>
                      <p className="mb-0">{data?.totalNoQuestion}</p>
                    </div>
                    <div>
                      <p className="mb-0">Sessions</p>
                      <p className="mb-0">{data?.totalNoSession}</p>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Card>
    </>
  );
}

export default NewQuizz;

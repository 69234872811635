import { gql } from '@apollo/client';

export const GET_CONFIG = gql`
  query GetConfig {
    getConfig {
      config {
        id
        companyLogo
        companyName
        primaryColor
        instructions
      }
    }
  }
`;
export const GET_COMPANYLOGO_UPLOAD_SIGNEDURL = gql`
  query GetCompanyLogoUploadSignedUrl(
    $data: GetCompanyLogoUploadSignedUrlInput
  ) {
    getCompanyLogoUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { LIMIT } from '../../../common/constants';
import TableComponent from '../../../components/CommonTable';
import useRouter from '../../../hooks/useRouter';
import { GET_PARTICIPANTS } from '../graphql/Queries';
import SessionUserList from './SessionUserList';

function SessionDetails({
  sessionList,
  setPaginationConfig,
  getSessionLoading,
  paginationConfig,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { params } = useRouter();

  const [
    getParticipants,
    { data: participantsData, loading: participantsLoading },
  ] = useLazyQuery(GET_PARTICIPANTS, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        sessionId: params?.id,
      },
    },
    onError() {},
  });

  const sessionColumns = [
    {
      title: 'Date & Time',
      dataIndex: 'sessionDate',
      key: 'sessionDate',
      render: (_, value) =>
        dayjs(value?.sessionDate)?.format('DD-MM-YYYY h:mm:ss A'),
    },
    {
      title: 'Highest Score',
      dataIndex: 'highestScore',
      key: 'highestScore',
      render: (_, value) =>
        Number(value?.highestScore) > 0
          ? Math?.round(Number(value?.highestScore) * 100) / 100
          : 0,
    },
    {
      title: 'Participants',
      dataIndex: 'totalUsers',
      key: 'totalUsers',
      render: (_, value) => value?.totalUsers || 0,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, { id = '', totalUsers }) =>
        totalUsers !== '0' ? (
          <Tooltip title="View user list">
            <span className="pointer">
              <EyeOutlined
                onClick={async () => {
                  const { data } = await getParticipants({
                    variables: {
                      data: {
                        sessionId: id,
                      },
                    },
                  });
                  if (data) {
                    setIsModalOpen(true);
                  }
                }}
              />
            </span>
          </Tooltip>
        ) : (
          <EyeInvisibleOutlined />
        ),
    },
  ];

  return (
    <>
      <TableComponent
        columns={sessionColumns}
        data={sessionList?.getSessions?.sessions}
        loadingData={getSessionLoading}
        paginationConfig={{
          ...paginationConfig,
          total: sessionList?.getSessions?.count,
        }}
        onChange={(pagination) => {
          setPaginationConfig((prev) => ({
            ...prev,
            skip: (pagination?.current - 1) * LIMIT,
          }));
        }}
      />
      <SessionUserList
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        participantsData={participantsData}
        participantsLoading={participantsLoading}
      />
    </>
  );
}

export default SessionDetails;

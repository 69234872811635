import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { MODE_OF_CONDUCT, STATUS_LIST } from '../../../common/constants';
import QuillEditor from '../../../components/Editor/QuillEditor';

const QuizDetailsForm = ({ showInstruction = true }) => (
  <>
    <Col span={showInstruction ? 12 : 24}>
      <Row gutter={16}>
        <Col span={24}>
          {' '}
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Title required!' }]}
          >
            <Input placeholder="Enter Quiz Title" maxLength={100} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Description required!' }]}
          >
            <Input placeholder="Enter Description" maxLength={250} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Quiz Start Time"
            name="quizInitialDelay"
            rules={[
              {
                required: true,
                message: 'Quiz start time required!',
              },
            ]}
          >
            <InputNumber placeholder="Enter Time (Seconds)" min={1} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Question Attempt Time"
            name="questionInterval"
            rules={[
              {
                required: true,
                message: 'Question attempt time required!',
              },
            ]}
          >
            <InputNumber placeholder="Enter Time (Seconds)" min={5} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Marks Per Question"
            name="marksPerQuestion"
            rules={[
              { required: true, message: 'Marks per question required!' },
            ]}
          >
            <InputNumber placeholder="Enter Marks Per Question" min={1} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="publishStatus"
            label="Publish Status"
            rules={[{ required: true, message: 'Status required!' }]}
          >
            <Select placeholder="Select Status">
              {map(STATUS_LIST, (list) => (
                <Select.Option key={list?.value} value={list?.value}>
                  {list?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="modeOfConduct" label="Mode">
            <Select
              placeholder="Select mode"
              defaultValue={MODE_OF_CONDUCT.QUIZ.key}
            >
              {map(MODE_OF_CONDUCT, (mode) => (
                <Select.Option key={mode?.key} value={mode?.key}>
                  {mode?.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Col>
    {showInstruction && (
      <Col span={12} className="instructions">
        <Form.Item
          label="Instruction"
          name="instruction"
          rules={[{ required: true, message: 'Instruction required!' }]}
        >
          <QuillEditor />
        </Form.Item>
      </Col>
    )}
  </>
);

export default QuizDetailsForm;

import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';

const Logout = () => {
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    dispatch({ type: 'LOGOUT' });
    // eslint-disable-next-line no-undef
    window.location = ROUTES.LOGIN;
  }, []);
  return null;
};
export default Logout;

import { gql } from '@apollo/client';

export const CREATE_CONFIG = gql`
  mutation CreateConfig($data: CreateConfigInputData!) {
    createConfig(data: $data) {
      message
    }
  }
`;

export const UPDATE_CONFIG = gql`
  mutation UpdateConfig($data: UpdateConfigInputData!) {
    updateConfig(data: $data) {
      message
    }
  }
`;

export const CREATE_QUIZ = gql`
  mutation createQuiz($data: CreateQuizAndQuestionInput!) {
    createQuiz(data: $data) {
      message
      quiz {
        id
        title
        description
        publishStatus
        questionInterval
        marksPerQuestion
        quizInitialDelay
        slug
        totalNoQuestion
        instruction
      }
      questions {
        id
        correctAnswer
        createdAt
        title
        type
        optionsValues {
          id
          title
        }
      }
    }
  }
`;

export const UPDATE_QUIZ = gql`
  mutation updateQuiz($data: UpdateQuizAndQuestionInput!) {
    updateQuiz(data: $data) {
      message
    }
  }
`;

export const CREATE_SESSION = gql`
  mutation CreateSession($data: CreateSessionInput!) {
    createSession(data: $data) {
      sessions {
        id
        slug
      }
    }
  }
`;

export const DELETE_QUIZ = gql`
  mutation DeleteQuiz($data: DeleteQuizInputType!) {
    deleteQuiz(data: $data) {
      message
    }
  }
`;

import { DownOutlined, LoginOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Space } from 'antd';
import { capitalize } from 'lodash';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';

const UserProfile = () => {
  const { pathname } = useLocation();
  const { getCurrentUser, dispatch } = useContext(AppContext);
  const userData = getCurrentUser();

  const items = [
    {
      key: 'onboarding',
      label: <Link to={ROUTES?.ONBOARDING}>Onboarding</Link>,
      icon: <UserOutlined />,
    },

    {
      key: 'logout',
      label: userData?.isConfigSet ? (
        <Link to={ROUTES?.LOGOUT}>Logout</Link>
      ) : (
        <span
          onClick={() => {
            dispatch({ type: 'LOGOUT' });
            // eslint-disable-next-line no-undef
            window.location = ROUTES.LOGIN;
          }}
        >
          Logout
        </span>
      ),
      icon: <LoginOutlined />,
    },
  ];

  return (
    <Dropdown
      menu={{ items, selectedKeys: [`${pathname?.split('/')?.[1]}`] }}
      trigger={['click']}
      className="user-profile"
    >
      <Space className="pointer d-flex align-center gap-4" size="small">
        {userData?.companyName?.[0] && (
          <Avatar className="header-avatar" alt="Avatar">
            {capitalize(userData?.companyName?.[0])}
          </Avatar>
        )}
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default UserProfile;

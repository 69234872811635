import { useEffect, useState } from 'react';

const useTimer = (endTime = 120) => {
  const [started, setStarted] = useState(false);
  const [time, setTime] = useState(endTime);

  useEffect(() => {
    if (started) {
      const id = setInterval(() => {
        setTime((prev) => {
          const newTime = prev - 1;
          if (newTime <= 0) {
            clearInterval(id);
            setStarted(false);
            return 0;
          }
          return newTime;
        });
      }, 1000);

      return () => {
        clearInterval(id);
      };
    }
  }, [started]);

  const start = () => {
    setStarted(true);
  };

  const end = () => {
    setStarted(false);
    setTime(endTime);
  };

  return { time, started, start, end, setTime };
};

export default useTimer;

import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';
import LoaderComponent from './components/LoaderComponent';

const PrivateRoute = () => {
  const { getCurrentUser, getToken, authLoading } = useContext(AppContext);
  const userData = getCurrentUser();
  const { pathname } = useLocation();
  const idToken = getToken();

  if (authLoading) {
    return <LoaderComponent />;
  }

  if (!idToken) {
    return <Navigate to={ROUTES?.LOGIN} />;
  }

  if (
    idToken &&
    userData?.isConfigSet === false &&
    pathname !== ROUTES.ON_BOARDING
  ) {
    return <Navigate to={ROUTES?.ON_BOARDING} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;

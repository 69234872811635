import { gql } from '@apollo/client';

export const GET_QUIZ = gql`
  query GetQuiz($filter: QuizFilter) {
    getQuiz(filter: $filter) {
      quiz {
        id
        title
        publishStatus
        description
        totalNoQuestion
        slug
        modeOfConduct
        totalNoSession
      }
    }
  }
`;
export const GET_SINGLE_QUIZ_BY_ID = gql`
  query getQuizById($data: GetQuizByIdInputType) {
    getQuizById(data: $data) {
      quiz {
        description
        publishStatus
        marksPerQuestion
        intervalTime
        instruction
        questions {
          type
          title
          correctAnswer
          optionsValues {
            id
            title
          }
          createdAt
          id
        }
        quizInitialDelay
        title
        id
        modeOfConduct
      }
    }
  }
`;

export const GET_SESSIONS = gql`
  query GetSessions($data: GetSessionsInput, $filter: SessionFilter) {
    getSessions(data: $data, filter: $filter) {
      count
      sessions {
        id
        highestScore
        totalUsers
        sessionDate
        slug
      }
    }
  }
`;
export const GET_PARTICIPANTS = gql`
  query GetParticipants($data: GetParticipantsInput) {
    getParticipants(data: $data) {
      participants {
        score
        sessionId
        userName
        totalCorrectAnswers
      }
    }
  }
`;

import React from 'react';

const List = () => (
  <svg viewBox="0 0 18 18">
    <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4" />
    <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9" />
    <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14" />
    <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4" />
    <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9" />
    <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14" />
  </svg>
);

export default List;

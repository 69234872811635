import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useRouter from './useRouter';

const usePreventNavigation = (isDirty) => {
  const navigate = useNavigate();
  const { location } = useRouter();

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.onpopstate = function () {
      if (
        isDirty &&
        // eslint-disable-next-line no-alert, no-undef
        !window.confirm(
          'If you leave before saving, your changes will be lost.',
        )
      ) {
        navigate(location.pathname);
      } else {
        // do nothing
      }
    };
  }, [isDirty]);
};

export default usePreventNavigation;
